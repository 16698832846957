import React, { useState, useEffect } from 'react';
import { Container, Accordion, Form, Table, Spinner, Button, Card, Row, Col } from 'react-bootstrap';
import AuthService from '../services/Auth-service';
import { ToastContainer, toast } from 'react-toastify';
import * as Icon from 'react-bootstrap-icons';

export default function EditProfile(props) {
    const { user } = props;
    //console.log(user)

    const [email, setEmail] = useState(user.email);
    const [firstName, setFirstName] = useState(user.f_name);
    const [lastName, setLastName] = useState(user.l_name);

    const [contactNo, setMobile] = useState(user.contactNo);
    const [birthDay, setBirthDay] = useState(user.birthday);
    const [gender, setGender] = useState(user.gender);

    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);

    const handleSubmitChangeUserData = async (e) => {
        e.preventDefault();
        setLoading1(true);
        const data = {
            "id": user.id,
            "email": email,
            "firstName": firstName,
            "lastName": lastName,
            "contactNo": contactNo,
            "birthDay": birthDay,
            "gender": gender,
        }
        //console.log(JSON.stringify(data));
        AuthService.updateUser(data).then((res) => {
            if (res.error) {
                toast.error(res.status);
                setLoading1(false);
            } else {
                toast.success(res.status);
                setLoading1(false);
            }
        });

    }

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordResetMsg, setPasswordResetMsg] = useState('');

    const handleSubmitChangePassword = async (e) => {
        e.preventDefault();
        setPasswordResetMsg("");
        setLoading2(true);
    
        if(newPassword !== confirmPassword){         
            setPasswordResetMsg("Passwords do not match.");
            //alert(passwordResetMsg);
            setLoading2(false);
        }else if(newPassword !== currentPassword){
            const data = {
                "id": user.id,
                "email": email,
                "currentPassword":currentPassword,
                "newPassword": newPassword,
            }
            //console.log(JSON.stringify(data));
            AuthService.updatePassword(data).then((res)=>{
                if (res.error) {
                    toast.error(res.status);
                    setLoading2(false);
                } else {
                    toast.success(res.status);
                    setCurrentPassword('');
                    setNewPassword('');
                    setConfirmPassword('');
                    setLoading2(false);
                }
            })
        }else{
            setPasswordResetMsg("New asswords and current password same");
            //alert(passwordResetMsg);
            setLoading2(false);
        }
    }
    return (
        <div>
            <Container className='bg-light py-5'>
                <div>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><Icon.PersonCheck className='mx-2'/>Edit profile data</Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <Row>
                                        <Col></Col>
                                        <Col sm={12} md={8}>
                                            <Form onSubmit={handleSubmitChangeUserData}>
                                                <Form.Group className="mb-3" controlId="email">
                                                    <Form.Control disabled onChange={(e) => setEmail(e.target.value)} value={email} type="email" placeholder="Enter email" required isInvalid={false} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="fname">
                                                    <Form.Control onChange={(e) => setFirstName(e.target.value)} value={firstName} type="text" placeholder="First Name" required isInvalid={false} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="lname">
                                                    <Form.Control onChange={(e) => setLastName(e.target.value)} value={lastName} type="text" placeholder="Last Name" required isInvalid={false} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback>
                                                </Form.Group>


                                                <Form.Group className="mb-3" controlId="birthday">
                                                    <Form.Control onChange={(e) => setBirthDay(e.target.value)} value={birthDay} type="text" placeholder="Birthday (1988-08-23)" required isInvalid={false} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Select onChange={(e) => setGender(e.target.value)} value={gender} className="mb-3" aria-label="gender">
                                                    <option value={''}>Select gender</option>
                                                    <option value={"0"}>Male</option>
                                                    <option value={"1"}>Female</option>
                                                    <option value={"2"}>Other</option>
                                                </Form.Select>

                                                <Form.Group className="mb-3" controlId="mobile">
                                                    <Form.Control onChange={(e) => setMobile(e.target.value)} value={contactNo} type="text" placeholder="Mobile number" required isInvalid={false} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback>
                                                    <small>{passwordResetMsg}</small>
                                                </Form.Group>
                                                <Button disabled={loading1} className='float-end' variant="primary" type="submit">
                                                    {loading1 ? (<><Spinner animation="border" size="sm" /> Loading ...</>) : ('Update')}
                                                </Button>
                                            </Form>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><Icon.Key className='mx-2'/>Change password</Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <Row>
                                        <Col></Col>
                                        <Col sm={12} md={6}>
                                            <Form onSubmit={handleSubmitChangePassword}>
                                                <Form.Group className="mb-3" controlId="passwordCurrent">
                                                    <Form.Control onChange={(e) => setCurrentPassword(e.target.value)} value={currentPassword} type="password" placeholder="Current password" required isInvalid={false} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="passwordNew">
                                                    <Form.Control onChange={(e) => setNewPassword(e.target.value)} value={newPassword} type="password" placeholder="New password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required isInvalid={false} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="cpasswordConfirm">
                                                    <Form.Control onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} type="password" placeholder="Confirm password" required isInvalid={false} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please choose a username.
                                                    </Form.Control.Feedback>
                                                    <small className='text-danger'>{passwordResetMsg}</small>
                                                </Form.Group>
                                                <Button disabled={loading2} className='float-end' variant="primary" type="submit">
                                                    {loading2 ? (<><Spinner animation="border" size="sm" /> Loading ...</>) : ('Change Password')}
                                                </Button>
                                            </Form>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><Icon.Trash className='mx-2'/>Delete Account</Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <div>
                                        <p>To delete your account on a website, you should first check if the website has a dedicated page or form for account deletion. Many websites provide such a form or page, which can be found in the account settings or profile section.</p>
                                        <p>It's important to note that once you delete your account, all your data and information associated with the account will be permanently deleted and cannot be recovered. So, make sure you have a backup of any important information before deleting your account.</p>
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <Button disabled={loading3} className='' variant="outline-danger" type="submit">
                                            {loading3 ? (<><Spinner animation="border" size="sm" /> Loading ...</>) : ('Delete Account')}
                                        </Button>
                                    </div>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Container>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}
