import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';

export default function Footer() {
    return (
            <div className='footer'>
                <div className='main-footer py-4 text-primary'>
                    <Container>
                        <Row>
                            <Col xs={12} md={4}>
                                <div className='brand'>
                                    <a href={'/'}><img src="/logo-g.png" alt='autodelers logo' /></a>
                                    <p>Welcome to autodealers.lk, the ultimate destination for all your vehicle needs. We offer a wide range of vehicles from top brands, including cars, trucks, motorcycles, and more. Our platform is user-friendly, and you can easily find the perfect vehicle that suits your budget and preferences.</p>
                                </div>
                            </Col>
                            <Col xs={12} md={3}>
                                <h6>Quick Links</h6>
                                <ul className='list-unstyled'>
                                    <li><Icon.ArrowUpRightSquareFill /><a href="https://gic.gov.lk/gic/" target="_blank">Government Infomation Center</a></li>
                                    <li><Icon.ArrowUpRightSquareFill /><a href="https://www.trc.gov.lk/" target="_blank">TRC</a></li>
                                    <li><Icon.ArrowUpRightSquareFill /><a href="https://www.police.lk/" target="_blank">Police Department</a></li>
                                    <li><Icon.ArrowUpRightSquareFill /><a href="https://www.colombo.mc.gov.lk/" target="_blank">Fire Department</a></li>
                                    <li><Icon.ArrowUpRightSquareFill /><a href="https://dmt.gov.lk/" target="_blank">Department of Motor Traffic</a></li>
                                </ul>
                            </Col>
                            <Col xs={12} md={3}>
                                <h6>Help & Support</h6>
                                <ul className='list-unstyled'>
                                    <li><Icon.ArrowUpRightSquareFill /><Link to="/terms&condition">Terms & Conditions</Link></li>
                                    <li><Icon.ArrowUpRightSquareFill /><Link to="/privacypolicy">Privacy Policy</Link></li>
                                    <li><Icon.ArrowUpRightSquareFill /><Link to="/faq">FAQs</Link></li>
                                    <li><Icon.ArrowUpRightSquareFill /><Link to="/aboutus">About us</Link></li>
                                    <li><Icon.ArrowUpRightSquareFill /><Link to="/contact">Contact us</Link></li>
                                </ul>
                            </Col>
                            <Col xs={12} md={2}>
                                <h6>Follow Us</h6>
                                <ul className='list-unstyled'>
                                    <li><Icon.Facebook /><a href="https://web.facebook.com/" title="Facebook" target="_blank"> Facebook</a></li>
                                    <li><Icon.Linkedin /><a href="https://lk.linkedin.com/" title="Linkedin" target="_blank">Linkedin</a></li>
                                    <li><Icon.Twitter /><a href="https://twitter.com/" title="Twitter" target="_blank">Twitter</a></li>
                                    <li><Icon.Youtube /><a href="https://www.youtube.com/" title="Youtube" target="_blank">Youtube</a></li>
                                </ul>

                                <ul className='list-unstyled'>
                                    <li><Icon.EnvelopeFill /><a href="mailto:info@autodealers.lk" title="Email"> info@autodealers.lk</a></li>
                                    <li><Icon.Whatsapp /><a href="https://wa.me/+94772567871" title="Contact" target="_blank">+94 77 2567871</a></li>
                                </ul>
                                
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className='copyright bg-primary text-secondary'>
                    <Container>
                        <div className='text-center py-2'>&copy; Copyright {new Date().getFullYear()} - www.autodealers.lk.  All rights reserved.</div>
                    </Container>
                </div>
            </div>
    )
}
