import React, { useContext, useState, useEffect } from 'react';
import DataContext from '../services/MyContext';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import * as Icon from 'react-bootstrap-icons';

export default function SearchPanel() {
    const navigate = useNavigate();
    const { 
        districList,
        vehicleBrandsList,
        vehicleColourList,
        yearsList,
        vehicleTypeList,
        vehicleModel,
        vehicleFuelType, vehicleConditionList,

        sVehicleMake, setSVehicleMake,
        sVehicleType, setSVehicleType,
        sVehicleModel, setSVehicleModel,
        sVehicleColor, setSVehicleColor,
        sVehicleBodyStyle, setSVehicleBodyStyle,
        sVehicleManuYS, setSVehicleManuYS,
        sVehicleManuYE, setSVehicleManuYE,
        sVehicleMinPrice, setSVehicleMinPrice,
        sVehicleMaxPrice, setSVehicleMaxPrice,
        sVehicleOwenersip, setSVehicleOwnersip,

        sVehicleTransmission, setSVehicleTransmission,
        sVehicleCondition, setSVehicleCondition,
        sVehicleFuel, setSVehicleFuel,
        sVehicleDistrict, setSVehicleDistrict,
        
        SearchAds } = useContext(DataContext);
    
    //console.log(vehicleModel);

    const [moreSreach, setMoreSreach] = useState(false);

    const clickMoreSrearch = () =>{
        setSVehicleTransmission("0");
        setSVehicleCondition("0");
        setSVehicleFuel("0");
        setSVehicleDistrict("0");
        if(moreSreach){
            setMoreSreach(false);
            //alert(moreSreach)
        }else{
            setMoreSreach(true);
            //alert(moreSreach)
        }
    }

    const Search = () => {
        const data ={
            "vType":sVehicleType,
            "vBrands":sVehicleMake,
            "vModel":sVehicleModel,
            "vColour":sVehicleColor,
            "mYearS":sVehicleManuYS,
            "mYearE":sVehicleManuYE,
            "maxPrice":sVehicleMaxPrice,
            "minPrice":sVehicleMinPrice,
            "vBtype":sVehicleBodyStyle,
            "vOwnership":sVehicleOwenersip,

            "vTransmission":sVehicleTransmission,
            "vCondition":sVehicleCondition,
            "vFuel":sVehicleFuel,
            "district":sVehicleDistrict,
          }
        const newSerch = true;
        SearchAds(data, newSerch, 0).then((res)=>{
            if(res){
                navigate('/ads');
            }
        });
    };

    return (
        <div className='w-100'>
            <Form>
                <Row lg={4} md={2} sm={2} xs={2} className='g-2'>
                    <Col className='my-2'>
                        <Form.Select onChange={(e) => setSVehicleMake(e.currentTarget.value)} value={sVehicleMake} size="sm">
                            <option value={"0"}> All Brands</option>
                            {vehicleBrandsList.map((b, index)=>(
                                <option key={index} value={b.BRAND_ID}>{b.BRAND}</option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col className='my-2'>
                        <Form.Select onChange={(e) => setSVehicleType(e.currentTarget.value)} value={sVehicleType} size="sm">
                            <option value={"0"}>All types</option>
                            {vehicleTypeList.map((t, index)=>(
                                <option key={index} value={t.VTYPE_ID}>{t.V_TYPE}</option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col className='my-2'>
                        <Form.Select onChange={(e) => setSVehicleModel(e.currentTarget.value)} value={sVehicleModel} size="sm">
                            <option value={"0"}>All models</option>
                            {vehicleModel.filter(model =>{
                                if(sVehicleType === "0"){
                                    if(sVehicleMake === "0"){
                                        return model;
                                    }else {return model.MAKE === sVehicleMake;}
                                    
                                }else if(sVehicleMake === "0"){
                                        return model.V_TYPE === sVehicleType;
                                }else{return model.V_TYPE === sVehicleType && model.MAKE === sVehicleMake;}
                            }).map((m, index)=>(
                                <option key={index} value={m.MODEL_ID}>{m.MODEL}</option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col className='my-2'>
                        <Form.Select onChange={(e) => setSVehicleColor(e.currentTarget.value)} value={sVehicleColor} size="sm">
                            <option value={"0"}>All colors</option>
                            {vehicleColourList.map((c, index)=>(
                                <option key={index} value={c.COLOR_ID}>{c.COLOUR}</option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col className='my-2'>
                        <Row>
                            <Col className='pe-0'>
                                <Form.Select onChange={(e) => setSVehicleManuYS(e.currentTarget.value)} value={sVehicleManuYS} size="sm">
                                    <option value={"0"}>Min year</option>
                                    {yearsList.map((y, index)=>(
                                        <option key={index} value={y}>{y}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col className='ps-2'>
                                <Form.Select onChange={(e) => setSVehicleManuYE(e.currentTarget.value)} value={sVehicleManuYE} size="sm">
                                    <option value={"0"}>Max year</option>
                                    {yearsList.map((y, index)=>(
                                        <option key={index} value={y}>{y}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='my-2'>
                        <Row>
                            <Col className='pe-0'>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Control onChange={(e) => setSVehicleMinPrice(e.currentTarget.value)} value={sVehicleMinPrice} size="sm" type="number" min="0" placeholder="Min price" />
                                </Form.Group>
                            </Col>
                            <Col className='ps-2'>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Control onChange={(e) => setSVehicleMaxPrice(e.currentTarget.value)} value={sVehicleMaxPrice} size="sm" type="number" min="0" placeholder="Max Price" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='my-2'>
                        <Form.Select onChange={(e) => setSVehicleOwnersip(e.currentTarget.value)} value={sVehicleOwenersip} size="sm">
                            <option value={""}>Any Owner</option>
                            <option value={"0"}>Unregistered</option>
                            <option value={"1"}>1st Owner</option>
                            <option value={"2"}>2nd Owner</option>
                            <option value={"3"}>3rd Owner</option>
                            <option value={"4"}>4th Owner</option>
                            <option value={"5"}>5th Owner</option>
                            <option value={"6"}>6th Owner</option>
                            <option value={"7"}>7th Owner</option>
                            <option value={"8"}>8th Owner</option>
                            <option value={"9"}>9th Owner</option>
                            <option value={"10"}>10th Owner</option>
                            <option value={"100"}>Other Owner</option>
                        </Form.Select>
                    </Col>
                    {moreSreach ? (
                        <>
                        <Col className='my-2'>
                        <Form.Select onChange={(e) => setSVehicleTransmission(e.currentTarget.value)} value={sVehicleTransmission} size="sm">
                            <option value={"0"}>Any Transmission</option>
                            <option value="1">Automatic</option>
                            <option value="2">Manual</option>
                        </Form.Select>
                    </Col>
                    <Col className='my-2'>
                        <Form.Select onChange={(e) => setSVehicleCondition(e.currentTarget.value)} value={sVehicleCondition} size="sm">
                            <option value={"0"}> Any Condition</option>
                            {vehicleConditionList.map((c, index) => (
                            <option key={index} value={c.CONDITION_ID} >{c.V_CONDITION}</option>
                                ))}
                        </Form.Select>
                    </Col>
                    <Col className='my-2'>
                        <Form.Select onChange={(e) => setSVehicleFuel(e.currentTarget.value)} value={sVehicleFuel} size="sm">
                            <option value={"0"}> Any Fuel</option>
                            {vehicleFuelType.map((f, index) => (
                          <option key={index} value={f.FUEL_TYPE_ID} >{f.FUEL_TYPE}</option>
                        ))}
                        </Form.Select>
                    </Col>
                    <Col className='my-2'>
                        <Form.Select onChange={(e) => setSVehicleDistrict(e.currentTarget.value)} value={sVehicleDistrict} size="sm">
                            <option value={"0"}> Any District</option>
                            {districList.map((b, index)=>(
                                <option key={index} value={b.DISTRICT_ID}>{b.NAME_EN}</option>
                            ))}
                        </Form.Select>
                    </Col>
                        
                        </>
                    ):("")}
                    <Col className='my-2'><Button size="sm" onClick={Search} variant="dark w-100"><Icon.Search />&nbsp;&nbsp;Search</Button></Col>
                </Row>
                <div className='w-100 text-center mt-2'>
                <Button onClick={()=>clickMoreSrearch()} className='' size="sm" variant="outline-secondary">{moreSreach ?("Hide More Search"):("Show More Search")}</Button>
                </div>
            </Form>

        </div>
    )
}
