import React, { useState, useEffect } from 'react';
import AuthService from '../services/Auth-service';
import { useNavigate, Link, Redirect } from "react-router-dom";
import { Container, Card, Form, Button, Spinner, Alert } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

export default function ForgetPassword() {
    const [userID, setUserID] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isForgetSuccess, setIsForgetSuccess] = useState(false);
    const [isForgetError, setIsForgetError] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setIsForgetSuccess(false);
        setIsForgetError(false);
        if (userID) {
            AuthService.forgetPassword(userID).then((res) => {
                if (res.error) {
                    setMessage(res.status);
                    setIsForgetError(true);
                    setUserID('');
                    //console.log(res.error);
                    setLoading(false);

                } else {
                    //console.log(res);
                    setMessage(res.status);
                    setIsForgetSuccess(true);
                    setUserID('');
                    setLoading(false);
                }
                

            });
        } else {
            setLoading(false);
        }
    }

  return (
    <div>
        <Container className='mt-5'>
            <div className='login-form'>
                <h4 className='text-center p-2'>Forget Password</h4>
                <Card className='bg-light'>
                    <Card.Body>
                        <Alert variant='success' className='p-2' show={isForgetSuccess}>
                            <span style={{ fontSize: 12 }}>{message}</span>
                        </Alert>
                        <Alert variant='danger' className='p-2' show={isForgetError}>
                            <span style={{ fontSize: 12 }}>{message}</span>
                        </Alert>

                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Control onChange={(e) => setUserID(e.target.value)} value={userID} type="email" placeholder="Enter email" required isInvalid={false} />
                            </Form.Group>
                            <Button disabled={loading} className='w-100 my-1' variant="primary" type="submit">
                                {loading ? (<><Spinner animation="border" size="sm" /> Please wait ...</>) : ('Submit')}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
                <Card className='mt-2'>
                    <Card.Body><span style={{ fontSize: 14 }}>Don't have an account? <Link to={'/register'}> Register</Link> now.</span></Card.Body>
                </Card>
            </div>
        </Container>
    </div>
  )
}
