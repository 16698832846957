import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Form, Button, Alert, Spinner, Accordion } from 'react-bootstrap';
import DataContext from '../services/MyContext';
import * as Icon from 'react-bootstrap-icons';
import DataService from '../services/Data-services';
import Resizer from "react-image-file-resizer";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { v4 as uuidv4 } from 'uuid';

export default function CreateAds(props) {
  const navigate = useNavigate();

  const { user } = props;

  const { vehicleBrandsList, vehicleColourList, yearsList,
    vehicleTypeList, vehicleModel, vehicleBodyStyle,
    vehicleFuelType, vehicleSTDFeatures, vehicleSTDFeaturesCat, vehicleConditionList,
    cityList, districList, proviceList, countryList } = useContext(DataContext);

  const [ uploadedFiles, setUploadedFiles ] = useState([]);
  const MAX_COUNT = 6;

  const [message, setMessage] = useState("");

  const handleFileEvent = (event) => {
    const files = event.target.files;

    const uploaded = [...uploadedFiles];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const ids = uuidv4();
      const fileSize = (((file.size) / 1024) / 1024).toFixed(4);
      // Handle file upload or processing
      if (fileSize < 5) {
        try {
          resizeImage(file, 600, 500, 100, (uri) => {
            if (uploaded.length === MAX_COUNT) {
              //alert('You can only add a maximum of ' + MAX_COUNT + ' files')
              toast.error('You can only add a maximum of ' + MAX_COUNT + ' files');
              
            } else {
              const newFile = { id: ids, file: uri };
              uploaded.push(newFile);
              setUploadedFiles(uploaded);
            }
          });
        } catch (err) {
          console.log(err);
          // Handle resize error
        }
      } else {
        // Handle file size too large error
        toast.error('You can only add a maximum file size 5Mb');
      }
    }
    //console.log(uploadedFiles);
    //setUploadedFiles(uploaded);
  }

  const resizeImage = (file, maxWidth, maxHeight, quality, callback) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'WEBP',
      quality,
      0,
      (uri) => {
        callback(uri);
      },
      'file'
    );
  };

  const handleRemoveItem = (id) => {
    var result = window.confirm("Want to delete?");
    if (result) {
      let hardCopy = [...uploadedFiles];
      //hardCopy = hardCopy.filter(file => file.name !== name);
      for (var i = 0; i < hardCopy.length; ++i) {
        if (hardCopy[i].id === id) {
          //console.log("match at: " + i);
          // remove the one element at the index where we get a match
          hardCopy.splice(i, 1);
          setUploadedFiles(hardCopy);
        }

      }
    }

  }

  function onDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const newItems = [...uploadedFiles];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setUploadedFiles(newItems)
  }



  const [name, setName] = useState(user.f_name + " " + user.l_name);
  const [phone, setPhone] = useState(user.contactNo);
  const [vehicleType, setVehicleType] = useState('');
  const [vehicleCondition, setVehicleCondition] = useState('');
  const [vehicleMake, setVehicleMake] = useState('');
  const [vehicleModelNo, setVehicleModelNo] = useState('');
  const [vehicleModelNoT, setVehicleModelNoT] = useState('');
  const [vehicleColor, setVehicleColor] = useState('');
  const [vehicleBodyType, setVehicleBodyType] = useState('');
  const [vehicleMfYear, setVehicleMfYear] = useState('');
  const [madeIn, setMadeIn] = useState('');
  const [vehicleRegYear, setVehicleRegYear] = useState('');
  const [engineCapacity, setEngineCapacity] = useState('');
  const [transmission, setTransmission] = useState('');
  const [fuelType, setFuelType] = useState('');
  const [mileage, setMileage] = useState('');
  const [ownership, setOwnership] = useState('');
  const [checkedSTDF, setCheckedSTDF] = useState([]);
  const [otherD, setOtherD] = useState('');

  const [province, setProvice] = useState('');
  const [distric, setDistric] = useState('');
  const [city, setCity] = useState('');
  const [price, setPrice] = useState('');
  const [leasing, setLeasing] = useState('0');

  useEffect(() => {
    setCheckedSTDF(vehicleSTDFeatures);
    //console.log(checkedSTDF);
  }, [vehicleSTDFeatures]);

  // Add/Remove checked item from list
  const handleCheckSTDF = (event) => {
    var updatedList = [...checkedSTDF];
    const upd_obj = updatedList.findIndex((obj => obj.SF_ID === event.target.value));
    if (event.target.checked === true) {
      updatedList[upd_obj].STATUS = "1";
      //console.log(updatedList);
    } else {
      updatedList[upd_obj].STATUS = "0";
    }
    setCheckedSTDF(updatedList);
    //console.log(checkedSTDF);
  };

  const handleCheckLeasing = (event) => {
    if (event.target.checked === true) {
      setLeasing("1");
    } else {
      setLeasing("0");
    }
  }

  const [validated, setValidated] = useState(false);
  const [upLoading, setUpLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setUpLoading(true);
    setSuccessful(false);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setUpLoading(false);
    } else {

      if (uploadedFiles.length > 3 && uploadedFiles.length <= 6) {

        var imgFilesArray = [];
        uploadedFiles.forEach(file => {
          imgFilesArray.push(file.file);
        });
        //console.log(imgFilesArray);

        DataService.uploadAdsImg(imgFilesArray).then(res => { // then print response status
          //console.log(res);
          var updateImgURLS = [];
          for (var i = 0; i < res.length; ++i) {
            if (res[i].error === false) {
              updateImgURLS = [...updateImgURLS, res[i].url];
            } else return;
          }
          //console.log(updateImgURLS);

          const data = {
            "personalData": {
              "id": user.id,
              "name": name,
              "phone": phone,
              "province": province,
              "distric": distric,
              "city": city,
            },
            "vehicleData": {
              "vehicleMake": vehicleMake,
              "madeIn": madeIn,
              "vehicleModelNo": vehicleModelNo,
              "vehicleModelNoT": vehicleModelNoT,
              "vehicleType": vehicleType,
              "vehicleColor": vehicleColor,
              "fuelType": fuelType,
              "engineCapacity": engineCapacity,
              "vehicleBodyType": vehicleBodyType,
              "transmission": transmission,
              "vehicleMfYear": vehicleMfYear,
              "vehicleRegYear": vehicleRegYear,
              "vehicleCondition": vehicleCondition,
              "mileage": mileage,
              "ownership": ownership,
              "otherD": otherD,
            },
            "standardFeatures": checkedSTDF,
            "imgURLS": updateImgURLS,
            "pricing": {
              "price": price,
              "leasing": leasing,
            }
          }

          // console.log(JSON.stringify(data));
          //alert(JSON.stringify(data));
          DataService.uploadNewAds(data).then((res) => {
            if (res.error) {
              setSuccessful(false);
              setMessage(res.status);
              console.log(res.error);
              toast.error(res.status);
              setUpLoading(false);
            } else {
              setMessage(res.status);
              setSuccessful(true);
              //console.log(res.status);
              setUpLoading(false);
            }

          });

        });
      } else {
        setSuccessful(false);
        toast.error("The elected imges file is insufficient!");
        //alert("The elected imges file is insufficient!");
        setUpLoading(false);
      }

    }

    setValidated(true);
  };

  const [agreement, setAgreement] = useState(false);

  return (
    <>
      <Container className='bg-light py-3'>
        <div><Button onClick={() => (navigate(-1))} variant="outline-danger" className="float-end">Go Back</Button></div>
      </Container>
      <Container className='bg-light py-5'>
        <h4 className='text-center my-3'>Create new advertisement</h4>
        {successful ? (
          <>
            <Row>
              <Col></Col>
              <Col sm={12} md={8}>
                <Alert variant="success">
                  <Alert.Heading>{message}</Alert.Heading>
                  <p>
                    Your ad has been successfully forwarded to our team. will be publish after their supervision. (ඔබගේ දැන්වීම සාර්ථකව අපගේ කණ්ඩායම වෙත යොමු කර ඇත. ඔවුන්ගේ අධීක්ෂණයෙන් පසුව ප්‍රකාශයට පත් කෙරේ.)
                  </p>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <Button onClick={() => navigate(-1)} variant="outline-success">
                      Back to profile
                    </Button>
                  </div>
                </Alert>
              </Col>
              <Col></Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col md={2}></Col>
              <Col md={8}>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <h5 className='my-4'>Contact Info</h5>
                  <Form.Group as={Row} className="mb-3" controlId="name">
                    <Form.Label column sm="3">
                      Your Name*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control onChange={(e) => setName(e.currentTarget.value)} value={name} required type="text" placeholder="PP. Kumara" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="phoneNo">
                    <Form.Label column sm="3">
                      Phone number*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control onChange={(e) => setPhone(e.currentTarget.value)} value={phone} required type="text" placeholder="07112345678" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="province">
                    <Form.Label column sm="3">
                      Province*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select onChange={(e) => setProvice(e.currentTarget.value)} value={province} required aria-label="Default select example">
                        <option value={''} >Open this select menu</option>
                        {proviceList.map((p, index) => (
                          <option key={index} value={p.PROVINCE_ID}>{p.NAME_EN}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="distric">
                    <Form.Label column sm="3">
                      Distric*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select onChange={(e) => setDistric(e.currentTarget.value)} value={distric} required aria-label="Default select example">
                        <option value={''} >Open this select menu</option>
                        {districList.filter(distric => {
                          return distric.PROVINCE_ID === province;
                        }).map((filterDistric, index) => (
                          <option key={index} value={filterDistric.DISTRICT_ID}>{filterDistric.NAME_EN}</option>
                        ))}

                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="city">
                    <Form.Label column sm="3">
                      City*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select onChange={(e) => setCity(e.currentTarget.value)} value={city} required aria-label="Default select example">
                        <option value={''} >Open this select menu</option>
                        {cityList.filter(city => {
                          return city.DISTRICT_ID === distric;
                        }).map((filterCity, index) => (
                          <option key={index} value={filterCity.CITY_ID}>{filterCity.NAME_EN}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <h5 className='my-4'>Vehicle Info</h5>
                  <Form.Group as={Row} className="mb-3" controlId="vehicleType">
                    <Form.Label column sm="3">
                      Vehicle Type*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select onChange={(e) => setVehicleType(e.currentTarget.value)} value={vehicleType} required aria-label="Default select example">
                        <option value={''} >Select type</option>
                        {vehicleTypeList.map((t, index) => (
                          <option key={index} value={t.VTYPE_ID}>{t.V_TYPE}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="vehicleMake">
                    <Form.Label column sm="3">
                      Vehicle Make*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select onChange={(e) => setVehicleMake(e.currentTarget.value)} value={vehicleMake} required aria-label="Default select example">
                        <option value={''} >Select make</option>
                        {vehicleBrandsList.map((m, index) => (
                          <option key={index} value={m.BRAND_ID}>{m.BRAND}</option>
                        ))}

                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="vehicleModel">
                    <Form.Label column sm="3">
                      Model*:
                    </Form.Label>
                    <Col sm="9">
                      <Row>
                        <Col>
                          <Form.Select onChange={(e) => setVehicleModelNo(e.currentTarget.value)} value={vehicleModelNo} aria-label="Default select example">
                            <option value={''} >Select model</option>
                            {vehicleModel.filter(model => {
                              return model.MAKE === vehicleMake && model.V_TYPE === vehicleType;
                            }).map((m, index) => (
                              <option key={index} value={m.MODEL_ID}>{m.MODEL}</option>
                            ))}
                          </Form.Select>
                        </Col> Or
                        <Col>
                          <Form.Control onChange={(e) => setVehicleModelNoT(e.currentTarget.value)} value={vehicleModelNoT} disabled={vehicleModelNo} required type="text" placeholder="GV-23fdg" />
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="vehicleColor">
                    <Form.Label column sm="3">
                      Body style*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select onChange={(e) => setVehicleBodyType(e.currentTarget.value)} value={vehicleBodyType} required aria-label="Default select example">
                        <option value={''} >Select body type</option>
                        {vehicleBodyStyle.filter(BodyStyle => {
                          return BodyStyle.V_TYPE === vehicleType
                        }).map((b, index) => (
                          <option key={index} value={b.BODY_TYPE_ID} >{b.BODDY_TYPE}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="vehicleColor">
                    <Form.Label column sm="3">
                      Colour*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select onChange={(e) => setVehicleColor(e.currentTarget.value)} value={vehicleColor} required aria-label="Default select example">
                        <option value={''} >Select colour</option>
                        {vehicleColourList.map((c, index) => (
                          <option key={index} value={c.COLOR_ID}>{c.COLOUR}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="engineCapasity">
                    <Form.Label column sm="3">
                      Engine Capacity (CC)*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control onChange={(e) => setEngineCapacity(e.currentTarget.value)} value={engineCapacity} required type="number" min="0" placeholder="1200" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="transmission">
                    <Form.Label column sm="3">
                      Transmission*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select onChange={(e) => setTransmission(e.currentTarget.value)} value={transmission} required aria-label="Delect Transmission">
                        <option value={''} >Select transmission</option>
                        <option value="1">Automatic</option>
                        <option value="2">Manual </option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="fuelType">
                    <Form.Label column sm="3">
                      Fuel Type*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select onChange={(e) => setFuelType(e.currentTarget.value)} value={fuelType} required aria-label="Select Fuel Type">
                        <option value={''} >Select fuel type</option>
                        {vehicleFuelType.map((f, index) => (
                          <option key={index} value={f.FUEL_TYPE_ID} >{f.FUEL_TYPE}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="madeIn">
                    <Form.Label column sm="3">
                      Made In*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select onChange={(e) => setMadeIn(e.currentTarget.value)} value={madeIn} required aria-label="Select made In">
                        <option value={''} >Select Country</option>
                        {countryList.map((c, index) => (
                          <option key={index} value={c.COUNTRY_ID}>{c.COUNTRY_NAME}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="mafYear">
                    <Form.Label column sm="3">
                      Manuf Year*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select onChange={(e) => setVehicleMfYear(e.currentTarget.value)} value={vehicleMfYear} required aria-label="Select Year">
                        <option value={''} >Select year</option>
                        {yearsList.map((y, index) => (
                          <option key={index} value={y}>{y}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="vehicleCondition">
                    <Form.Label column sm="3">
                      Vehicle Condition*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select onChange={(e) => setVehicleCondition(e.currentTarget.value)} value={vehicleCondition} required aria-label="Default select example">
                        <option value={''} >Select condition</option>
                        {vehicleConditionList.map((c, index) => (
                          <option key={index} value={c.CONDITION_ID} >{c.V_CONDITION}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>


                  <Form.Group as={Row} className="mb-3" controlId="regYear">
                    <Form.Label column sm="3">
                      Reg Year*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select onChange={(e) => setVehicleRegYear(e.currentTarget.value)} value={vehicleRegYear} required aria-label="Select Year">
                        <option value={''} >Select year</option>
                        {yearsList.map((y, index) => (
                          <option key={index} value={y}>{y}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="mileage">
                    <Form.Label column sm="3">
                      Mileage (km)*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control onChange={(e) => setMileage(e.currentTarget.value)} value={mileage} required min="0" type="number" placeholder="150000" />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="regOwner">
                    <Form.Label column sm="3">
                      Ownership*:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select onChange={(e) => setOwnership(e.currentTarget.value)} value={ownership} required aria-label="Select owner">
                        <option value={''} >Select owner</option>
                        <option value={'1'} >1st Owner</option>
                        <option value={'2'} >2nd Owner</option>
                        <option value={'3'} >3rd Owner</option>
                        <option value={'4'} >4th Owner</option>
                        <option value={'5'} >5th Owner</option>
                        <option value={'6'} >6th Owner</option>
                        <option value={'7'} >7th Owner</option>
                        <option value={'8'} >8th Owner</option>
                        <option value={'9'} >9th Owner</option>
                        <option value={'10'} >10th Owner</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>


                  <h5 className='my-4'>Accessories & options</h5>
                  <Form.Group className="mb-4 small" controlId="stdFatures">
                    <Accordion defaultActiveKey="0">
                      {vehicleSTDFeaturesCat.map((cat, i) => (
                        <Accordion.Item eventKey={i} key={i}>
                          <Accordion.Header>{cat.CATEGORY}</Accordion.Header>
                          <Accordion.Body>
                            <Row xs={2} sm={3}>
                              {vehicleSTDFeatures.filter(x => {
                                return x.FEATURE_CAT === cat.CAT_ID;
                              }).map((std, index) => (
                                <Col key={index}>
                                  <Form.Check
                                    value={std.SF_ID}
                                    onChange={handleCheckSTDF}
                                    type="checkbox"
                                    id={std.FEATURE}
                                    label={std.FEATURE}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}

                    </Accordion>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="aditionalInfo">
                    <Form.Label column sm="3">
                      Remarks:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control onChange={(e) => setOtherD(e.currentTarget.value)} value={otherD} as="textarea" rows={4} />
                    </Col>
                  </Form.Group>

                  <h5 className='my-4'>Pricing</h5>
                  <Form.Group as={Row} className="mb-3" controlId="price">
                    <Form.Label column sm="3">
                      Price (LKR)*:
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control onChange={(e) => setPrice(e.currentTarget.value)} value={price} required type="number" min="0" placeholder="150000" />
                    </Col>
                    <Col sm="5">
                      <Form.Check
                        value={leasing}
                        onChange={handleCheckLeasing}
                        type="checkbox"
                        id="custom-switch"
                        label="Ongoing vehicle leasing"
                      />
                    </Col>
                  </Form.Group>
                  <h5 className='my-4'>Vehicle Images</h5>
                  <Form.Group as={Row} className="mb-3" controlId="img">
                    <Form.Label column sm="3"></Form.Label>
                    <Col sm="9">

                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction="horizontal" >
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className='crop-img-div'
                            >
                              {uploadedFiles.map((file, index) => (
                                <Draggable draggableId={file.id} index={index} key={file.id}>
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className='images'
                                    >
                                      <div>
                                        <Button className='notify-badge border-0' onClick={() => handleRemoveItem(file.id)} >x</Button>
                                        <img src={URL.createObjectURL(file.file)} alt='img'></img>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>


                      <div className='custom-file-input' htmlFor="file-upload">
                        <label htmlFor="file-upload">
                          <Icon.Upload /> Upload Vehicle Images ({uploadedFiles.length} of 6)
                        </label>
                        <input id="file-upload" type="file" onChange={handleFileEvent} accept="image/*" multiple />
                      </div>

                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="my-4" controlId="agreement">
                    <Form.Label column sm="3">

                    </Form.Label>
                    <Col sm="9">
                      <Form.Check
                        required
                        value={agreement}
                        onChange={(e) => setAgreement(e.target.checked)}
                        type="checkbox"
                        id="custom-agreement"
                        label="I agree to the terms and conditions"
                      />
                    </Col>
                  </Form.Group>

                  <Button disabled={!agreement || successful} className='float-end' variant="outline-primary" type="submit">
                    {upLoading ? (<><Spinner animation="border" size="sm" /> Uploading ...</>) : ('Save')}
                  </Button>
                </Form>
              </Col>
              <Col md={2}></Col>
            </Row>
          </>)}
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />


    </>
  )
}
