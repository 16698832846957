import React, { useEffect } from 'react';
import { useLocation } from "react-router";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

export default function ContactUs() {
  //Scroll To Top on Route Change
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])
  //-------------------------------
  return (
    <>
      <Container className='bg-light py-5'>
        <h5 className='text-center py-5'>ContactUs</h5>
        <Row>
          <Col></Col>
          <Col sm={12} md={6}>
            <Form>

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Email address*</Form.Label>
                    <Form.Control type="email" placeholder="name@example.com" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                    <Form.Label>Phone number*</Form.Label>
                    <Form.Control type="text" placeholder="+94768987675" />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>How many we help you?*</Form.Label>
                <Form.Control as="textarea" placeholder="Help me understand how I can help you?" rows={4} />
              </Form.Group>
              <Button className="float-end" variant="primary">Send <Icon.Send /></Button>
            </Form>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </>
  )
}
