import React, { useState, useEffect } from 'react';
import { Container, Table, Spinner, Button, Card, Row, Col } from 'react-bootstrap';
import axiosInstance from '../services/Axios';
import DataService from '../services/Data-services';
import { useNavigate, Link } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';

import { ToastContainer, toast } from 'react-toastify';


export default function Profile(props) {
  const { user } = props;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [adsList, setAdsList] = useState([]);
  const userAdsList = () => {
    setLoading(true);
    const userId = user.id;
    DataService.userAdsList(userId).then((res) => {
      if (res) {
        setAdsList(res);
        setLoading(false);
        //console.log(JSON.stringify(res));
      } else {
        setAdsList([]);
        setLoading(false);
      }
    })

  }

  useEffect(() => {
    userAdsList();
  }, [user]);

  let CurrencyLKR = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'LKR',
  });

  const deleteAds = (adsRefNo) => {
    const userId = user.id;
    const data = {
      "adsRefNo": adsRefNo,
      "userId": userId,
    }
    var result = window.confirm("Want to delete?");
    if (result) {
      DataService.deleteSingleAds(data).then((res) => {
        if (res.error) {
          //alert(res.status);
          toast.error(res.status);
        } else {
          let hardCopy = [...adsList];
          hardCopy = hardCopy.filter((cItem) => cItem.ADS_REF_NO !== adsRefNo);
          setAdsList(hardCopy);
          toast.success(res.status);
          //alert(res.status);
          //userAdsList();
        }
      })
    } else return;
  }

  return (
    <div>
      <Container className='bg-light py-5'>
        <div className='mb-5'>
          <Card>
            <Card.Header>Hi here! Welcome to our page</Card.Header>
            <Card.Body>
              <div className='profile'>
                <div className='profile-img'>
                <img src="/profile-icon.webp"></img>
                </div>
                <div className='profile-content'>
                  <Card.Title>{user.f_name+" "+user.l_name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {user.email+" "} 
                    <Icon.Check2Circle /> 
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">{user.contactNo}</Card.Subtitle>
                  <Link className='float-end' to={'/dash/profile-edit'}>Edit</Link >
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        {loading ? (
          <>
            <div className="w-100">
              <h4 className='my-5 text-center'><Spinner animation="grow" size="sm" /> Loading advertising...</h4>
            </div>
          </>
        ) : (
          <>

            {adsList.length === 0 ? (
              <>
                <h5 className='my-5 text-center'>Currently your advertisement not found</h5>
              </>
            ) : (
              <>
                <div>
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>IMG</th>
                        <th>Ref Number</th>
                        <th>Vehicle</th>
                        <th>Price</th>
                        <th>Biding</th>
                        <th>Confom</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {adsList.map((a, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td><img className="s-img" src={axiosInstance.defaults.baseURL + a.IMG_URL} alt='images'/></td>
                          <td><Link className='text-decoration-none' to={`/view-ads/${a.ADS_REF_NO}`}>{a.ADS_REF_NO}</Link></td>
                          <td>{a.BRAND} {a.MODEL} {a.MANU_YEAR}</td>
                          <td>{CurrencyLKR.format(a.PRICE)}</td>
                          <td>{a.BIDING}</td>
                          <td>{a.CONFIRMATION}</td>
                          <td>
                            <Button onClick={() => navigate(`/dash/editads/${a.ADS_REF_NO}`)} size="sm" variant="outline-info">Edit</Button>{' '}
                            <Button onClick={() => deleteAds(a.ADS_REF_NO)} size="sm" variant="outline-danger">Delete</Button>{' '}
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </Table>
                </div>
              </>
            )}
          </>
        )}

      </Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  )
}
