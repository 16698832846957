import React, { Component, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Container, Card, Button, Badge, Row, Col, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import axiosInstance from "../services/Axios";
import {
  useNavigate,
  useParams
} from "react-router-dom";
import DataService from "../services/Data-services";
import Slider from "react-slick";

export default function ViewAds() {
  const navigate = useNavigate();

  //Scroll To Top on Route Change
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])
  //-------------------------------

  const { id } = useParams('');
  const [loding, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [adsInfo, setAdsInfo] = useState([]);
  const [adsSDF, setAdsSDF] = useState([]);
  const [imgList, setImgList] = useState([]);
  const [mainImg, setMainImg] = useState('');

  const loadAds = (adsRefNo) => {
    setLoading(true);
    setMessage("");
    DataService.getSearchSingleAds(adsRefNo).then((res) => {
      if (res) {
       //console.log(res);
        setAdsInfo(res.adsInfo);

        setMainImg(res.adsInfo.IMG_URL);
        setImgList(res.images);

        var newArray = res.stdF.filter((el) => {
          return el.STATUS === '1';
        });
        setAdsSDF(newArray);

        //console.log(mainImg);
        setLoading(false);
      } else {
        setMessage("Can't identify this advertisement");
        setLoading(false);
      }
    })
  }

  useEffect(() => {
    loadAds(id);
  }, [id]);

  let CurrencyLKR = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'LKR',
  });

  return (
    <>
    <Container className='bg-light py-3'>
    <div><Button onClick={()=>(navigate(-1))} variant="outline-danger" className="float-end">Go Back</Button></div>
    </Container>

      <Container className='bg-light py-5'>
        <div className='ads-view-container'>
          {loding ? (
            <div className="w-100">
              <h4 className='my-5 text-center'><Spinner animation="grow" size="sm" /> Loading for advertising...</h4>
            </div>
          ) : (
            <div>
              <div className="">
                <h3>{adsInfo.BRAND} {adsInfo.MODEL} {adsInfo.MANU_YEAR} - {adsInfo.V_CONDITION}</h3>
                <p>Posted by {adsInfo.OWNERSIP} {adsInfo.NAME} on {adsInfo.UPLOAD_AT}, Location {adsInfo.CITY}, {adsInfo.DISTRICTS}, {adsInfo.PROVINCE}.</p>
                <h5>Advertisement Ref: {adsInfo.ADS_REF_NO}</h5>
              </div>

              <div className="ads-view-container">
                <Row xs={1} sm={1} md={1} lg={2}>
                  <Col className="mb-4">
                    <div className="img-div">
                      <div className="main-img">
                        <img className="w-100 " src={axiosInstance.defaults.baseURL + mainImg} alt="autodealers" />
                      </div>
                      <div className="img-gallery">
                        <Card className="mt-2 w-100 text-center">
                          <Card.Body className="p-2">
                            <div>
                              {imgList.map((img, index) => (
                                <img onClick={() => setMainImg(img.IMG_URL)} key={index} src={axiosInstance.defaults.baseURL + img.IMG_URL} alt="autodealers"/>
                              ))}
                            </div>
                          </Card.Body>
                        </Card>

                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="img-content">
                      <h4>Full specifications</h4>
                      <Row xs={1} sm={2} md={2} lg={2} xl={2}>
                        <Col>
                          <ul className='list-unstyled'>
                            <li><Icon.CheckSquareFill /> Brand: {adsInfo.BRAND} {adsInfo.COUNTRY_NAME}</li>
                            <li><Icon.CheckSquareFill /> Model: {adsInfo.MODEL}</li>
                            <li><Icon.CheckSquareFill /> Manu year: {adsInfo.MANU_YEAR}</li>
                            <li><Icon.CheckSquareFill /> Reg year: {adsInfo.REG_YEAR}</li>
                            <li><Icon.CheckSquareFill /> Mileage: {adsInfo.MILEAGE} km</li>
                            <li><Icon.CheckSquareFill /> Ownership: {adsInfo.OWNERSIP}</li>
                          </ul>
                        </Col>
                        <Col>
                          <ul className='list-unstyled'>
                            <li><Icon.CheckSquareFill /> Engine: {adsInfo.ENGINE} CC</li>
                            <li><Icon.CheckSquareFill /> Transmission: {adsInfo.TRANSMISSION}</li>
                            <li><Icon.CheckSquareFill /> Fuel: {adsInfo.FUEL_TYPE}</li>
                            <li><Icon.CheckSquareFill /> Color: {adsInfo.COLOUR}</li>
                            <li><Icon.CheckSquareFill /> Type: {adsInfo.V_TYPE}</li>
                            <li><Icon.CheckSquareFill /> Body style: {adsInfo.BODDY_TYPE}</li>
                          </ul>
                        </Col>
                      </Row>
                      <h4>Accessories & options</h4>
                      <div className="accessories my-4">
                        <Row xs={2} sm={3} md={8} lg={8} className='p-0 m-0'>
                          {adsSDF.map((f, index) => (
                            <Col key={index} className="p-1"><div className="option-card">{f.FEATURE}</div></Col>
                          ))}

                        </Row>
                      </div>
                      <h4>Remarks</h4>
                      <p>{adsInfo.OTHER_D}</p>
                      <h3>Price: {CurrencyLKR.format(adsInfo.PRICE)} <span className="text-danger">{adsInfo.V_LEASING}</span></h3>
                      <h4>Contact information</h4>
                      <h6>Conatct Number: <a href={"tel:"+adsInfo.PHONE}>{adsInfo.PHONE}</a></h6>
                      
                    </div>
                  </Col>
                </Row>

              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  )
}
