import React, { useEffect} from 'react';
import { useLocation } from "react-router";
import { Container, Row, Col } from 'react-bootstrap';

export default function AboutUs() {
          //Scroll To Top on Route Change
          const location = useLocation();
          useEffect(() => {
            window.scrollTo(0, 0);
          }, [location])
          //-------------------------------
  return (
    <>
    <Container className='bg-light py-5'>
      <h5 className='text-center'>About Us</h5>
      <Row>
        <Col></Col>
        <Col sm={12} md={8}>
        <p>Welcome to Autodealers, the premier destination for high-quality used cars, trucks, and SUVs. We are proud to serve customers throughout Sri Lanka and beyond, offering an extensive selection of vehicles from all the top brands.</p>
        <p>Our team is made up of dedicated professionals who are passionate about helping you find the perfect vehicle to suit your needs and budget. We understand that purchasing a used car can be overwhelming, which is why we work hard to provide a stress-free and enjoyable shopping experience.</p>
        <p>Our goal is to provide our customers with the best possible value for their money. That's why we offer competitive pricing and a range of financing options to help make your dream car a reality. Whether you have good credit, bad credit, or no credit at all, we'll work with you to find a payment plan that fits your budget.</p>
        <p>We're committed to building long-lasting relationships with our customers, and we believe that starts with providing exceptional customer service. Our friendly and knowledgeable staff is always on hand to answer any questions you may have and help you navigate the car-buying process.</p>
        <p>Thank you for choosing Autodealers for your next vehicle purchase. We look forward to helping you find the car of your dreams and providing you with a positive and enjoyable car-buying experience.</p>
        </Col>
        <Col></Col>
      </Row>
    </Container>
    </>
  )
}
