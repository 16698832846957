import React, { useEffect} from 'react';
import { useLocation } from "react-router";
import { Container, Row, Col } from 'react-bootstrap';

export default function PrivacyPolicy() {
        //Scroll To Top on Route Change
        const location = useLocation();
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [location])
        //-------------------------------
  return (
    <>
    <Container className='bg-light py-5'>
      <h4 className='text-center py-4'>Privacy Policy</h4>
      <Row>
        <Col></Col>
        <Col sm={12} md={8}>
        <p>This privacy policy ("Policy") describes how Autodealers ("we," "us," or "our") collects, uses, and shares personal information of users of this website www.autodealers.lk (the "Site"). This Policy also applies to any of our services that link to this Policy.</p>
        <h5>What We Collect</h5>
        <p>We may collect the following types of personal information when you use the Site:</p>
        <ul>
          <li>Contact information, such as your name, email address, and phone number</li>
          <li>Demographic information, such as your age and gender</li>
          <li>Information about your use of the Site, such as your browsing behavior and preferences</li>
        </ul>
        <p>We collect this information in the following ways:</p>
        <ul>
          <li>When you provide it to us, such as when you fill out a contact form or sign up for a newsletter</li>
          <li>Automatically through the use of cookies and other tracking technologies</li>
          <li>From third-party sources, such as social media platforms or publicly available data sources</li>
        </ul>
        <h5>How We Use Your Information</h5>
        <p>We may use your personal information for the following purposes:</p>
        <ul>
          <li>To provide the services and features of the Site</li>
          <li>To communicate with you about your account or our services</li>
          <li>To send you marketing and promotional messages about our services or those of our partners</li>
          <li>To improve and optimize the Site and our services</li>
          <li>To comply with legal and regulatory requirements</li>
        </ul>
        <h5>How We Share Your Information</h5>
        <p>We may share your personal information in the following ways:</p>
        <ul>
          <li>With third-party service providers who help us to operate the Site and provide our services</li>
          <li>With our partners for marketing and promotional purposes</li>
          <li>With law enforcement or other government authorities in response to a subpoena or other legal request</li>
          <li>In connection with a merger, acquisition, or other business transaction involving us or our assets</li>
        </ul>
        <h5>Your Choices</h5>
        <p>You may opt out of receiving marketing and promotional messages from us at any time by following the instructions in the message. Please note that even if you opt out, we may still send you non-promotional messages related to your use of the Site or our services.</p>
        <h5>Cookies and Tracking Technologies</h5>
        <p>We and our third-party service providers use cookies and other tracking technologies to collect information about your use of the Site and our services. You may be able to disable cookies through your browser settings, but doing so may limit your ability to use certain features of the Site.</p>
        <h5>Security</h5>
        <p>We take reasonable measures to protect your personal information from unauthorized access or disclosure. However, no security system is completely secure, and we cannot guarantee the security of your personal information.</p>
        <h5>Children's Privacy</h5>
        <p>The Site is not intended for use by children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are a parent or legal guardian of a child under the age of 13 who has provided personal information to us, please contact us at [contact email] to have the information removed.</p>
        <h5>Changes to the Policy</h5>
        <p>We reserve the right to modify this Policy at any time. If we make material changes to the Policy, we will notify you by email or by posting a notice on the Site prior to the effective date of the changes.</p>
        <h5>Contact Us</h5>
        <p>If you have any questions or concerns about this Policy or our privacy practices, please contact us at <a href="mailto:info@autodealers.lk" title="Email">info@autodealers.lk</a>.</p>
        </Col>
        <Col></Col>
      </Row>
    </Container>
    </>
  )
}
