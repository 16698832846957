import React, { useState, useEffect } from 'react';
import axiosInstance from "./Axios";
import { Navigate } from 'react-router-dom';

const saveAccessToken = (accessToken)=>{
  localStorage.setItem("accessToken", accessToken);
}

const saveUserData = (userData) => {
  localStorage.setItem("user", JSON.stringify(userData));
}

const Register = async (email, nameWithInitias, birthDay, gender, contactNo, password) => {
  return axiosInstance.post("user/user_registration.php", {
    email,
    nameWithInitias,
    contactNo,
    password,
    birthDay,
    gender
  })
    .then((response) => {
      return response.data;
    }, (error) => {
      const resMessage =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
          console.log(resMessage);
  });
};

const updateUser = async (data)=> {
  return axiosInstance.post("/user/user_update.php", data, {
  }).then((response)=>{

    if(response.data.userData){
      saveUserData(response.data.userData);
    }
      return response.data;
  }, (error) => {
    const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(resMessage);
});
}

const updatePassword = async (data)=> {
  return axiosInstance.post("/user/password_update.php", data, {
  }).then((response)=>{
      //console.log(JSON.stringify(response));
      if (response.data.accessToken) {
        saveAccessToken(response.data.accessToken);
      }
      return response.data;
  }, (error) => {
    const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(resMessage);
});
}

const forgetPassword = async (userID)=> {
  return axiosInstance.post("/user/forget_pw.php", { userID }).then((response)=>{
      //console.log(JSON.stringify(response));
      return response.data;
  }, (error) => {
    const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(resMessage);
});
}

const Login = async (userID, password) => {
  return axiosInstance.post("user/login.php", {
    userID,
    password,
  })
    .then((response) => {
      //console.log(JSON.stringify(response));
      if (response.data.accessToken) {
        //localStorage.setItem("accessToken", response.data.accessToken);
        //localStorage.setItem("user", JSON.stringify(response.data.userData));
        saveAccessToken(response.data.accessToken);
        saveUserData(response.data.userData);
      }
      return response.data;
    }, (error) => {
      const resMessage =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
          console.log(resMessage);
  });
};

const Logout = () => {
  localStorage.clear();
  return <Navigate to="/login" />;
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getaccessToken = () => {
  return localStorage.getItem("accessToken");
};

const AuthService = {
  Register, updateUser, updatePassword, forgetPassword,
  Login,
  Logout,
  getaccessToken, getCurrentUser,
}
export default AuthService;