import React, { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import axiosInstance from '../services/Axios';
import { Container, Card, Row, Col } from 'react-bootstrap';
import SearchPanel from './SearchPanel';
import * as Icon from 'react-bootstrap-icons';
import ProAdsPanel from './ProAdsPanel';
import DataContext from '../services/MyContext';

export default function Home() {
    const { initialsDataLoding, topBrandsList, topVTypeList,

        sVehicleMake, setSVehicleMake,
        sVehicleType, setSVehicleType,
        sVehicleModel, setSVehicleModel,
        sVehicleColor, setSVehicleColor,
        sVehicleBodyStyle, setSVehicleBodyStyle,
        sVehicleManuYS, setSVehicleManuYS,
        sVehicleManuYE, setSVehicleManuYE,
        sVehicleMinPrice, setSVehicleMinPrice,
        sVehicleMaxPrice, setSVehicleMaxPrice,
        sVehicleOwenersip, setSVehicleOwnersip,
        SearchAds } = useContext(DataContext);

    const navigate = useNavigate();

    //console.log(topBrandsList);
    const searchUnicBran = (brandId) => {
        setSVehicleMake(brandId);
        const data = {
            "vType": sVehicleType,
            "vBrands": sVehicleMake,
            "vModel": sVehicleModel,
            "vColour": sVehicleColor,
            "mYearS": sVehicleManuYS,
            "mYearE": sVehicleManuYE,
            "maxPrice": sVehicleMaxPrice,
            "minPrice": sVehicleMinPrice,
            "vBtype": sVehicleBodyStyle,
            "vOwnership": sVehicleOwenersip,
        }

        const newSerch = true;
        SearchAds(data, newSerch, 0).then((res) => {
            if (res) {
                navigate('/ads');
            }
        });
    }
    return (
        <div>
            <Container fluid className='bg-primary p-2'>
                <Container className='my-4'>
                    <div className='home-title'>
                        <h1>Make your vehicle dream come true.</h1>
                        <p>Welcome to autodealers.lk, the ultimate destination for all your vehicle needs. We offer a wide range of vehicles from top brands, including cars, trucks, motorcycles, and more. Our platform is user-friendly, and you can easily find the perfect vehicle that suits your budget and preferences. With just a few clicks, you can compare prices, and view high-quality images. Whether you're looking to buy a new car or sell your old one, our platform is the perfect place to start. So why wait? Browse our extensive collection of vehicles today and find your dream ride!</p>
                    </div>
                    <SearchPanel />
                </Container>
            </Container>
            <Container className=' p-2'>
                <div className='py-4'>
                    {initialsDataLoding ? (
                    <>
                    <h5 className='my-5 text-center'>Loading ...</h5>
                    </>
                    ) : (
                        <>
                            <Row lg={5} md={4} sm={3} xs={2}>

                                {topBrandsList.map((x, index) => (
                                    <Col key={index} className='my-3'>
                                        <div onClick={() => searchUnicBran(x.BRAND_ID)} className='vehicle-top-brands-card'>
                                            <div className=''>
                                                <img className='v-logo' src={axiosInstance.defaults.baseURL + x.IMG_URL} alt='vehicle brands' />
                                            </div>
                                            <div className='content'>
                                                <span>{x.BRAND}</span>
                                                <p>{x.NO_COUNT} Ads</p>
                                            </div>
                                        </div>
                                    </Col>
                                ))}

                            </Row>
                        </>
                    )}

                </div>
            </Container>
        </div>
    )
}
