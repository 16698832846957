import { createContext, useState, useEffect } from "react";
import DataService from "./Data-services";

const DataContext = createContext();
export function DataProvider({ children }) {

  const [yearsList, setYearsList] = useState([]);
  const yearList = () => {
    let year_satart = 1940;
    let year_end = (new Date).getFullYear(); // current year
    var years = [];
    for (let i = year_satart; i <= year_end; i++) {
      years.push(i);
    }
    //console.log(years);
    setYearsList(years);
  };

  const [vehicleBrandsList, setVehicleBrandsList] = useState([]);
  const [vehicleModel, setVehicleModel] = useState([]);
  const [vehicleColourList, setVehicleColourList] = useState([]);
  const [vehicleSTDFeatures, setVehicleSTDFeatures] = useState([]);
  const [vehicleSTDFeaturesCat, setVehicleSTDFeaturesCat] = useState([]);
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [vehicleBodyStyle, setVehicleBodyStyle] = useState([]);
  const [vehicleFuelType, setVehicleFuelType] = useState([]);
  const [vehicleConditionList, setVehicleConditionList] = useState([]);

  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [districList, setDistricList] = useState([]);
  const [proviceList, setProviceList] = useState([]);

  const [topBrandsList, setTopBrandsList] = useState([]);
  const [topVTypeList, setTopVTypeList] = useState([]);

  const [initialsDataLoding, setInitialsDataLoding] = useState(true);

  const fetchAllVehicalCommonData = () => {
    setInitialsDataLoding(true);
    DataService.getVehicleAllCommon().then((res) => {
      if (res) {
        setVehicleBrandsList(res.BRANDS);
        setVehicleTypeList(res.V_TYPE);
        setVehicleModel(res.MODEL);
        setVehicleBodyStyle(res.BODY_STYLE);
        setVehicleColourList(res.COLOR);
        setVehicleSTDFeatures(res.SDF);
        setVehicleSTDFeaturesCat(res.SDF_CAT);
        setVehicleFuelType(res.FUEL_TYPE);
        setVehicleConditionList(res.V_CONDITION);

        setTopBrandsList(res.TOP_BRANDS);
        setTopVTypeList(res.TOP_TYPE);

        setCountryList(res.COUNTRY);
        setProviceList(res.PROVINCE);
        setDistricList(res.DISTRIC);
        setCityList(res.CITY);

        setInitialsDataLoding(false);
        //console.log(res.SDF);
      } else {
        setVehicleBrandsList([]);
        setVehicleModel([]);
        setVehicleBodyStyle([]);
        setVehicleColourList([]);
        setVehicleFuelType([]);
        setVehicleSTDFeatures([]);
        setVehicleSTDFeaturesCat([]);
        setVehicleTypeList([]);

        setTopBrandsList([]);
        setTopVTypeList([]);

        setCityList([]);
        setDistricList([]);
        setProviceList([]);

        setInitialsDataLoding(false);
      }
    })
    setInitialsDataLoding(false);
  };

  useEffect(() => {
    yearList();
    fetchAllVehicalCommonData();
  }, []);

  const [sVehicleMake, setSVehicleMake] = useState("0");
  const [sVehicleType, setSVehicleType] = useState("0");
  const [sVehicleModel, setSVehicleModel] = useState("0");
  const [sVehicleColor, setSVehicleColor] = useState("0");
  const [sVehicleBodyStyle, setSVehicleBodyStyle] = useState("0");
  const [sVehicleManuYS, setSVehicleManuYS] = useState("");
  const [sVehicleManuYE, setSVehicleManuYE] = useState("");
  const [sVehicleMinPrice, setSVehicleMinPrice] = useState("");
  const [sVehicleMaxPrice, setSVehicleMaxPrice] = useState("");
  const [sVehicleOwenersip, setSVehicleOwnersip] = useState("");

  const [sVehicleTransmission, setSVehicleTransmission] = useState("0");
  const [sVehicleCondition, setSVehicleCondition] = useState("0");
  const [sVehicleFuel, setSVehicleFuel] = useState("0");
  const [sVehicleDistrict, setSVehicleDistrict] = useState("0");

  const [serchAdsResult, setSerchAdsResult] = useState([]);
  const [serchAdsResultCount, setSerchAdsResultCount] = useState("0");
  const [adsLoading, setAdsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const SearchAds = (data, newSerch, page) => {
    setAdsLoading(true);
    return DataService.getSearchAds(data, page).then(res=>{
      //console.log(data);
      setSerchAdsResultCount(res.rowCount);
      if(newSerch){
        setSerchAdsResult([]);
        setSerchAdsResult(res.ads);
        setAdsLoading(false);
        return true;
      }else{
        setAdsLoading(false);
      }
    }, (error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(resMessage);
      setAdsLoading(false);
    })
    
  };

  return (
    <DataContext.Provider
      value={{
        initialsDataLoding,

        yearsList,

        vehicleBrandsList,
        vehicleModel,
        vehicleBodyStyle,
        vehicleColourList,
        vehicleSTDFeatures, vehicleSTDFeaturesCat,
        vehicleTypeList,
        vehicleFuelType,
        vehicleConditionList,

        topBrandsList, topVTypeList,

        countryList, proviceList, districList, cityList,

        sVehicleMake, setSVehicleMake,
        sVehicleType, setSVehicleType,
        sVehicleModel, setSVehicleModel,
        sVehicleColor, setSVehicleColor,
        sVehicleBodyStyle, setSVehicleBodyStyle,
        sVehicleManuYS, setSVehicleManuYS,
        sVehicleManuYE, setSVehicleManuYE,
        sVehicleMinPrice, setSVehicleMinPrice,
        sVehicleMaxPrice, setSVehicleMaxPrice,
        sVehicleOwenersip, setSVehicleOwnersip,

        sVehicleTransmission, setSVehicleTransmission,
        sVehicleCondition, setSVehicleCondition,
        sVehicleFuel, setSVehicleFuel,
        sVehicleDistrict, setSVehicleDistrict,

        SearchAds, adsLoading, serchAdsResult, serchAdsResultCount
      }}>
      {children}
    </DataContext.Provider>
  );
}

export default DataContext;