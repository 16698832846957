import axiosInstance from "./Axios";

  const getVehicleAllData = async () =>{
    const response = await axiosInstance.get("v_ads/get_all_ads.php");
    return response.data;
  }

  const getVehicleAllCommon = async () =>{
    const response = await axiosInstance.get("v_common_details/get_common_details_n.php");
    return response.data;
  }

  const getSearchAds = async (data, page) =>{
    return axiosInstance.post("v_ads/search_ads.php?page="+ page, data)
      .then((response) => {
        return response.data;
      }, (error) => {
        const resMessage =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
            console.log(resMessage);
    });
  }

  const getSearchSingleAds = async (adsRefNo) =>{
    return axiosInstance.post("v_ads/get_single_ads.php", {adsRefNo})
      .then((response) => {
        return response.data;
      }, (error) => {
        const resMessage =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
            console.log(resMessage);
    });
  }


  const uploadAdsImg = async (uploadedFiles)=> {
    const data = new FormData();
    for (let i = 0; i < uploadedFiles.length; i++) {
      data.append("file[]", uploadedFiles[i]);
    }
    return axiosInstance.post("/file_upload/multiple_files_upload.php", data, {
    }).then((response)=>{
        return response.data;
    }, (error) => {
      const resMessage =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
          console.log(resMessage);
  });
}

const editImgBeforeUpdate = async (data)=> {
  return axiosInstance.post("/v_ads/edit_img_before_update.php", data, {
  }).then((response)=>{
      return response.data;
  }, (error) => {
    const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(resMessage);
});
};

const uploadAdsImgNew = async (uploadedFiles)=> {
  const data = new FormData();
  for (let i = 0; i < uploadedFiles.length; i++) {
    data.append("file[]", uploadedFiles[i]);
  }
  return axiosInstance.post("/file_upload/multiple_files_upload_new.php", data, {
  }).then((response)=>{
      return response.data;
  }, (error) => {
    const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(resMessage);
});
}

const uploadNewAds = async (data)=> {
  return axiosInstance.post("/v_ads/add_ads.php", data, {
  }).then((response)=>{
      return response.data;
  }, (error) => {
    const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(resMessage);
});
}

const updateAds = async (data)=> {
  return axiosInstance.post("/v_ads/update_ads.php", data, {
  }).then((response)=>{
      return response.data;
  }, (error) => {
    const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(resMessage);
});
}

const userAdsList = async (userID)=> {
  return axiosInstance.post("/v_ads/get_single_user_ads_list.php", {userID}).then((response)=>{
      return response.data;
  }, (error) => {
    const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(resMessage);
});
}

const deleteSingleAds = async (data)=> {
  //console.log(JSON.stringify(data));
  return axiosInstance.post("/v_ads/delete_ads.php", data).then((response)=>{
      return response.data;
  }, (error) => {
    const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(resMessage);
});
}




const DataService = {
    getVehicleAllData,
    getVehicleAllCommon,
    uploadAdsImg, uploadNewAds, updateAds, deleteSingleAds, uploadAdsImgNew,editImgBeforeUpdate,
    getSearchAds,getSearchSingleAds,
    userAdsList,
  }
  export default DataService;

