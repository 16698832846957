import React, { useState, useEffect } from 'react';
import AuthService from '../services/Auth-service';
import { Card, Form, Button, Alert, Container, Spinner, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

export default function Register() {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [contactNo, setMobile] = useState('');
    const [password, setPassword] = useState('');

    const [birthDay, setBirthDay] = useState('');
    const [birthYear, setBirthYear] = useState('');
    const [birthMonth, setBirthMonth] = useState('');


    const [gender, setGender] = useState('');

    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage("");
        setSuccessful(false);
        const data ={
            "email": email,
            "firstName": firstName,
            "lastName": lastName,
            "birthDay": birthYear+"-"+birthMonth+"-"+birthDay,
            "gender":gender,
            "contactNo":contactNo,
            "password":password
        }
        if (email) {
            AuthService.Register(data).then((res) => {
                if (res.error) {
                    setMessage(res.status);
                    setSuccessful(false);
                    //console.log(res);
                    setLoading(false);
                    //window.location.reload();
                } else {
                    setMessage(res.status);
                    setSuccessful(true);
                    //console.log(res.error);
                    setLoading(false);
                }

            });
        } else {
            setLoading(false);
        }

    }

    const [yearsList, setYearsList] = useState([]);
    const [daysList, setDaysList] = useState([]);
    const yearList = () => {
      let year_satart = 1940;
      let year_end = (new Date).getFullYear(); // current year
      var years = [];
      for (let i = year_satart; i <= year_end; i++) {
        years.push(i);
      }
      //console.log(years);
      setYearsList(years);
    };

    const dayList = () => {
        let day_satart = 1;
        let day_end = 31; // current year
        var days = [];
        for (let i = day_satart; i <= day_end; i++) {
            days.push(i);
        }
        //console.log(years);
        setDaysList(days);
      };

    useEffect(() => {
        yearList();
        dayList();
      }, []);

    return (
        <Container fluid className='my-5'>
            <div className='reg-form'>
                <h4 className='text-center p-2'>Register</h4>
                <Card className='bg-light'>
                    <Card.Body>
                        {message && (
                            <Alert className='p-2' variant={successful ? 'success' : 'danger'}>
                                <span style={{ fontSize: 12 }}>{message}</span>
                            </Alert>
                        )}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Control onChange={(e) => setEmail(e.target.value)} value={email} type="email" placeholder="Enter email" required isInvalid={false} />
                                <Form.Control.Feedback type="invalid">
                                    Please choose a username.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="first-name">
                                <Form.Control onChange={(e) => setFirstName(e.target.value)} value={firstName} type="text" placeholder="First Name" required isInvalid={false} />
                                <Form.Control.Feedback type="invalid">
                                    Please choose a username.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="last-name">
                                <Form.Control onChange={(e) => setLastName(e.target.value)} value={lastName} type="text" placeholder="Last Name" required isInvalid={false} />
                                <Form.Control.Feedback type="invalid">
                                    Please choose a username.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3">
                            <Form.Label><small>Birthday</small></Form.Label>
                                <Row>
                                    <Col className=''>
                                        <Form.Select onChange={(e) => setBirthYear(e.target.value)} value={birthYear} required isInvalid={false} aria-label="birth-year">
                                            <option value={""}>Year</option>
                                            {yearsList.map((y, index)=>(
                                                <option key={index} value={y}>{y}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Col className='mx-0 px-0'>
                                    <Form.Select onChange={(e) => setBirthMonth(e.target.value)} value={birthMonth} required isInvalid={false} aria-label="birth-month">
                                            <option value={""}>Month</option>
                                            <option value="1">Jan</option>
                                            <option value="2">Feb</option>
                                            <option value="3">Mar</option>
                                            <option value="4">Apr</option>
                                            <option value="5">May</option>
                                            <option value="6">Jun</option>
                                            <option value="7">Jul</option>
                                            <option value="8">Aug</option>
                                            <option value="9">Sep</option>
                                            <option value="10">Oct</option>
                                            <option value="11">Nov</option>
                                            <option value="12">Dec</option>
                                        </Form.Select>
                                    </Col>
                                    <Col className=''>
                                    <Form.Select onChange={(e) => setBirthDay(e.target.value)} value={birthDay} required isInvalid={false} aria-label="birth-day">
                                            <option value={""}>Day</option>
                                            {daysList.map((d, index)=>(
                                                <option key={index} value={d}>{d}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-3">
                            <Form.Label><small>Gender</small></Form.Label>
                            <div className="mb-3" onChange={(e) => setGender(e.target.value)}>
                                <Form.Check inline type={'radio'} name={'gender'} value={0} label={'Male'} />
                                <Form.Check inline type={'radio'} name={'gender'} value={1} label={'Female'} />
                                <Form.Check inline type={'radio'} name={'gender'} value={2} label={'Other'} />
                            </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="mobile">
                                <Form.Control onChange={(e) => setMobile(e.target.value)} value={contactNo} type="text" placeholder="Mobile number" required isInvalid={false} />
                                <Form.Control.Feedback type="invalid">
                                    Please choose a username.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Control onChange={(e) => setPassword(e.target.value)} value={password} type="password" placeholder="New password" required isInvalid={false} />
                                <Form.Control.Feedback type="invalid">
                                    Please choose a username.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button disabled={loading} className='w-100' variant="primary" type="submit">
                                {loading ? (<><Spinner animation="border" size="sm" /> Loading ...</>) : ('Sign up')}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
                <Card className='mt-2'>
                    <Card.Body><span style={{ fontSize: 14 }}>Already have an account? <Link to="/login">Login</Link> now.</span></Card.Body>
                </Card>
            </div>
        </Container>

    )
}

