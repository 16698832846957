import React, { useContext, useEffect } from 'react';
import { useLocation } from "react-router";
import { Container, Spinner, Card, Form, Row, Col, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import AdsCard from './AdsCard';
import SearchPanel from './SearchPanel';
import DataContext from '../services/MyContext';

export default function AdsPanel() {
    
    const { topBrandsList, topVTypeList, SearchAds, adsLoading, serchAdsResult, serchAdsResultCount,
        sVehicleMake,
        sVehicleType,
        sVehicleModel,
        sVehicleColor,
        sVehicleBodyStyle,
        sVehicleManuYS,
        sVehicleManuYE,
        sVehicleMinPrice,
        sVehicleMaxPrice,
        sVehicleOwenersip,

        sVehicleTransmission,
        sVehicleCondition,
        sVehicleFuel,
        sVehicleDistrict,
    } = useContext(DataContext);

      //Scroll To Top on Route Change
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])
  //-------------------------------

    useEffect(() => {
        const data ={
            "vType":sVehicleType,
            "vBrands":sVehicleMake,
            "vModel":sVehicleModel,
            "vColour":sVehicleColor,
            "mYearS":sVehicleManuYS,
            "mYearE":sVehicleManuYE,
            "maxPrice":sVehicleMaxPrice,
            "minPrice":sVehicleMinPrice,
            "vBtype":sVehicleBodyStyle,
            "vOwnership":sVehicleOwenersip,

            "vTransmission":sVehicleTransmission,
            "vCondition":sVehicleCondition,
            "vFuel":sVehicleFuel,
            "district":sVehicleDistrict,
          }
        const newSerch = true;
        const page = 0;
        SearchAds(data, newSerch, page);
        //console.log(serchAdsResult);
    }, []);

    return (
        <div>
            <Container className='bg-light py-5'>
                <div className='ads-serch-panel'>
                <Row >
                    <Col sm={12} md={12} lg={3} className='d-none d-lg-block border-end border-secondary'>
                    <div className='left-manu'>
                        <h6>Vehicle Barands</h6>
                        <ul className='list-unstyled'>
                            {topBrandsList.map((l, index) => (
                                <li key={index}>{l.BRAND}<small> ({l.NO_COUNT})</small></li>
                            ))}
                        </ul>
                        <h6>Shop By Type</h6>
                        <ul className='list-unstyled'>
                            {topVTypeList.map((l, index) => (
                                <li key={index}>{l.V_TYPE}<small> ({l.NO_COUNT})</small></li>
                            ))}
                        </ul>
                    </div>
                    </Col>
                    <Col sm={12} md={12} lg={9}>
                        <div className=''>
                        <div className='my-4'>
                            <h4 className='mb-4'>Find your dream vehicle</h4>
                            <SearchPanel />
                        </div>
                        <h6>Search results ({serchAdsResultCount})</h6>
                        <p>We ensure the reliability and transparency of your deal and let you know that no third party is involved for this.</p>

                        <div className='my-2'>
                            {adsLoading ? (
                                <h5 className='my-5 text-center'><Spinner animation="grow" size="sm" /> Searching for advertising...</h5>
                            ) : (
                                <>
                                    <div>
                                        {serchAdsResultCount > 0 ? (
                                            <>
                                                {serchAdsResult.map((x, index) => (
                                                    <div key={index} className="my-3">
                                                        <AdsCard adsData={x} />
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                <h5 className='my-5 text-center'>Searching data not found</h5>
                                            </>
                                        )}

                                    </div>

                                </>
                            )}
                        </div>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
        </div>
    )
}
