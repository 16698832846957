import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Container, Row, Col, Form, Button, Alert, Spinner, Accordion } from 'react-bootstrap';
import Resizer from 'react-image-file-resizer';
import { v4 as uuidv4 } from 'uuid';

export default function ImgUpload() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const files = event.dataTransfer.files;
    handleFileChange(files);
  };

  const handleFileChange = async (files) => {
    const uploaded = [...uploadedFiles];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const ids = uuidv4();
      const fileSize = (((file.size) / 1024) / 1024).toFixed(4);
      // Handle file upload or processing
      if (fileSize < 5) {
        try {
          resizeImage(file, 600, 500, 100, (uri) => {
            const newFile = { id: ids, file: uri };
            uploaded.push(newFile);
            setUploadedFiles(uploaded);
          });
        } catch (err) {
          console.log(err);
          // Handle resize error
        }
      } else {
        // Handle file size too large error
      }
    }

    //setUploadedFiles(uploaded);
  };

  const handleFileInputClick = () => {
    // Programmatically click the file input element
    document.getElementById('file-input').click();
  };

  const handleFileInputChange = (event) => {
    event.preventDefault();
    const files = event.target.files;
    handleFileChange(files);
  };

  const resizeImage = (file, maxWidth, maxHeight, quality, callback) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'JPEG',
      quality,
      0,
      (uri) => {
        callback(uri);
      },
      'file'
    );
  };

  useEffect(() => {
    console.log(uploadedFiles);
  }, [uploadedFiles]);

  return (
    <div>
      <div
        className={`drop-area ${isDragging ? 'dragging' : ''}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleFileInputClick}
      >
        {isDragging ? 'Drop files here' : 'Drag and drop files here or click to browse'}
      </div>
      <input
        id="file-input"
        type="file"
        accept="image/*"
        onChange={handleFileInputChange}
        multiple
        style={{ display: 'none' }}
      />
      <div className="uploaded-files">
        {uploadedFiles.map((file, index) => (
          <div key={index}>{file.file.name}</div>
        ))}
      </div>
    </div>
  );
}
