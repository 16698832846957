import React, { useState, useEffect } from 'react';
import { Container, Card, Form, Row, Col } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import axiosInstance from '../services/Axios';
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";

export default function AdsCard(props) {
const { adsData } = props;

const navigate = useNavigate();

const [adsInfo, setAndInfo ] = useState([]);
const [imgList, setImgList] = useState([]);
const [mainImg, setMainImg] = useState([]);

useEffect(() => {
  if(adsData){
    setAndInfo(adsData.adsInfo);
    setMainImg(adsData.adsInfo.IMG_URL);
    setImgList(adsData.images)
    //console.log(axiosInstance.defaults.baseURL);
  }else return;
}, [adsData]);

const viewFullAds =(adsRefNo)=>{
  //alert(adsRefNo)
  navigate(`/view-ads/${adsRefNo}`);
}

let CurrencyLKR = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'LKR',
});

  return (
    <>
      <div className='ads-card-0'>
        <div className='img-div'>
          <div className='main-img'>
          <img onClick={()=>viewFullAds(adsInfo.ADS_REF_NO)} src={axiosInstance.defaults.baseURL+mainImg} alt='vehicle main img'/>
          </div>
          <div className='img-gallery'>
            {imgList.slice(0, 4).map((img, index)=>(
              <img onClick={()=>setMainImg(img.IMG_URL)} key={index} src={axiosInstance.defaults.baseURL+img.IMG_URL} alt='vehicle img' />
            ))}
          </div>
        </div>
        <div className='ads-container'>
        <h6 className='truncate m-0'><Link className='text-decoration-none' to={`/view-ads/${adsInfo.ADS_REF_NO}`}>{adsInfo.BRAND} {adsInfo.MODEL} - {adsInfo.MANU_YEAR}</Link></h6>
          <Row>
            <Col>
              <ul className='list-unstyled'>
                <li className='d-none d-md-block'><Icon.CheckSquareFill /> Engine: {adsInfo.ENGINE} CC </li>
                <li className='d-none d-md-block'><Icon.CheckSquareFill /> Mileage: {adsInfo.MILEAGE} km</li>
                <li className='d-none d-md-block'><Icon.CheckSquareFill /> Transmission: {adsInfo.TRANSMISSION}</li>
                <li className='d-none d-lg-block'><Icon.CheckSquareFill /> Fuel: {adsInfo.FUEL_TYPE}</li>
              </ul>
            </Col>
            <Col className='d-none d-md-block'>
            <ul className='list-unstyled'>
                <li><Icon.CheckSquareFill /> Color: {adsInfo.COLOUR}</li>
                <li><Icon.CheckSquareFill /> Manu year: {adsInfo.MANU_YEAR}</li>
                <li className='d-none d-md-block'><Icon.CheckSquareFill /> Reg year: {adsInfo.REG_YEAR}</li>
                <li className='d-none d-lg-block'><Icon.CheckSquareFill /> Owner: {adsInfo.OWNERSIP}</li>
              </ul>
            </Col>
          </Row>
          <h6>{CurrencyLKR.format(adsInfo.PRICE)}</h6>
          <small className='text-danger'>{adsInfo.V_LEASING}</small>
          <p className='truncate'><small>{adsInfo.UPLOAD_AT}, Location: {adsInfo.CITY}</small></p>
        </div>
      </div>
    </>
  )
}
