import React from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import CreateAds from './CreateAds';
import EditAds from './EditAds';
import EditProfile from './EditProfile';
import Profile from './Profile';

export default function Dashboard(props) {
  const { user } = props;
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Profile user={user} />} />
        <Route exact path="/profile" element={<Profile  user={user} />} />
        <Route exact path="/profile-edit" element={<EditProfile  user={user} />} />
        <Route exact path="/createads" element={<CreateAds user={user} />} />
        <Route exact path="/editads/:id" element={<EditAds user={user} />} />
      </Routes>
    </div>
  )
}
