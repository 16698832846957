import React from 'react';
import AuthService from '../services/Auth-service';
import { NavLink, useNavigate } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

export default function Header(props) {
    const { isLogin, user } = props;
    const navigate = useNavigate();

    const logout = async () => {
        var result = window.confirm("Are you sure you want to log out?");
        if (result) {
            AuthService.Logout();
            navigate('/login');
        } else return;

    };

    return (
        <div>
            <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
                <Container>
                    <Navbar.Brand >
                        <a className='text-decoration-none text-white' href={'/'}>
                            <div className='brand'>
                                <img src="/logo-w.png" alt='autodelers logo'></img>
                                <span>Autodealers [LK]</span>
                            </div>
                        </a>

                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto align-items-lg-center">
                            <Nav.Link className='d-none' as={NavLink} to="/home" >Home</Nav.Link>
                        </Nav>
                        <Nav className="align-items-lg-center">
                            {isLogin ? (
                                <NavDropdown title={'Hi.. ' + user.f_name+" "+user.l_name} id="collasible-nav-dropdown">
                                    <NavDropdown.Item as={NavLink} to="/dash/profile"><Icon.Person className='me-2' />Profile</NavDropdown.Item>
                                    <NavDropdown.Item as={NavLink} to="/dash/profile-edit"><Icon.Gear className='me-2' />Setting</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={logout}><Icon.BoxArrowRight className='me-2' />Logout</NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                <>
                                    <Nav.Link as={NavLink} to="/login">Log In</Nav.Link>
                                    <Nav.Link as={NavLink} to="/register">Sign Up</Nav.Link>
                                </>
                            )}
                            <Nav.Link as={NavLink} to={isLogin ? ('/dash/createads') : ('/login')}><Button variant="outline-secondary">Post Your Ads</Button></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}
