import React from 'react';
import { Container } from 'react-bootstrap';

export default function FAQ() {
  return (
    <>
    <Container className='bg-light py-5'>
      <h5 className='text-center'>FAQ</h5>
      <h6 className='text-center my-5'>--No select data--</h6>
    </Container>
    </>
  )
}
