import axios from "axios";
import AuthService from "./Auth-service";

const getBaseUrl = () => {
    let url;
    switch(process.env.NODE_ENV) {
      case 'production':
        url = 'https://api.autodealers.lk/';
        break;
      case 'development':
      default:
        url = 'https://api.autodealers.lk/';
    }
  
    return url;
  }

  let headers = {};
  const token = AuthService.getaccessToken();
   if(token){
      headers.Authorization = `Bearer ${token}`;
   };

  const axiosInstance = axios.create({
    baseURL: getBaseUrl(),
    //timeout: 1000,
    headers,
    });

  export default axiosInstance;