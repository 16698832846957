import React, { useEffect} from 'react';
import { useLocation } from "react-router";
import { Container, Row, Col } from 'react-bootstrap';

export default function TermsAndConditions() {
        //Scroll To Top on Route Change
        const location = useLocation();
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [location])
        //-------------------------------
  return (
    <>
    <Container className='bg-light py-5'>
      <h4 className='text-center py-4'>Terms & Conditions</h4>
      
      <Row>
        <Col></Col>
        <Col sm={12} md={8}>
        <p>Welcome to Autodealers LK, operated by Autodealers.LK. Please read these terms and conditions ("Terms") carefully before using the www.autodealers.lk website (the "Service").</p>
        <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.</p>
        <h5>Content</h5>
        <p>The Service allows you to access, view, and download content including but not limited to text, images, and videos ("Content"). The Content is protected by copyright and other intellectual property laws, and you may not use it except as permitted under these Terms.</p>
        <p>You may view, download, and print Content for your personal, non-commercial use, provided that you do not modify the Content in any way and that you retain all copyright and other proprietary notices contained in the Content.</p>
        <h5>Prohibited Conduct</h5>
        <p>You may not use the Service for any illegal or unauthorized purpose, including but not limited to:</p>
        <ul>
          <li>Posting or transmitting content that is unlawful, defamatory, harassing, or offensive;</li>
          <li>Impersonating another person or entity;</li>
          <li>Interfering with the operation of the Service;</li>
          <li>Using the Service to send spam or other unsolicited messages;</li>
          <li>Attempting to access or use another user's account without permission.</li>
        </ul>
        <h5>Intellectual Property</h5>
        <p>The Service and its Content are owned by Autodelers and are protected by copyright, trademark, and other intellectual property laws. You may not use the Service or the Content except as permitted under these Terms.</p>
        <p>You may not use any trademarks, logos, or other proprietary information of Autodelers without our prior written consent.</p>
        <h5>Links to Third-Party Websites</h5>
        <p>The Service may contain links to third-party websites that are not owned or controlled by Autodelers. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites.</p>
        <p>By using the Service, you expressly release Autodealers from any and all liability arising from your use of any third-party website.</p>
        <h5>Disclaimer of Warranties</h5>
        <p>The Service and its Content are provided "as is" and without warranty of any kind. Autodealers makes no representations or warranties of any kind, express or implied, as to the operation of the Service or the accuracy, completeness, or reliability of the Content.</p>
        <h5>Limitation of Liability</h5>
        <p>To the fullest extent permitted by law, Autodealers shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of the Service or the Content, even if [company name] has been advised of the possibility of such damages.</p>
        <h5>Indemnification</h5>
        <p>You agree to indemnify, defend, and hold harmless Autodealers and its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with your use of the Service or your violation of these Terms.</p>
        <h5>Changes to the Terms</h5>
        <p>We reserve the right to modify these Terms at any time. If we make material changes to these Terms, we will notify you by email or by posting a notice on the Service prior to the effective date of the changes.</p>

        </Col>
        <Col></Col>
      </Row>
    </Container>
    </>
  )
}
